<template>
  <div class="wrap" id="selectTarget">
    <div class="header">
      <h2 class="st_title mr-auto">
        <img src="@/assets/占星小鋪/friend.svg" alt="" />
        選擇星盤好友
      </h2>
      <div class="filterBar">
        <label>
          <img
            class="serachIcon"
            src="../../assets/占星小鋪/search.svg"
            alt=""
          />
          <input
            type="text"
            placeholder="搜尋星盤名稱"
            v-model="filterKey"
            @keyup.enter="filterHandler"
          />
        </label>
        <button class="filter_btn" @click="filterHandler">搜尋</button>
        <div class="addFriends_btn" @click="toAddFriend()">
          <img src="../../assets/占星小鋪/add friend.svg" alt="" />
        </div>
      </div>
    </div>

    <!-- 全部列表(627已改) -->
    <div class="st_list_group unlock_area">
      <v-row>
        <v-col
          cols="12"
          v-for="item in List_filter"
          :key="item.AstrolabeFileId"
        >
          <TargetCard
            class="TargetCard-space mx-auto"
            @clicked="selectHandler(item)"
            @next="next()"
            @edit="editHandler(item)"
            :selected="item.AstrolabeFileId == selectObj.AstrolabeFileId"
            :data="item"
          >
          </TargetCard>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import TargetCard from "../../components/訂單流程/TargetCard";
export default {
  components: {
    TargetCard,
  },
  data() {
    return {
      modeTabSelect: "lock",
      List_all: [],
      List_filter: [],
      filterKey: "",
      selectObj: { AstrolabeFileId: null },
    };
  },
  created() {
    if (!this.$store.state.orderCreater.ProductID) {
      return this.$router.replace("/");
    }
    this.$API.Get_MemberInfo().then((data) => {
      window.localStorage["loginIndex"] = data.Data.MemberId;
      this.$store.commit("set_userData", data.Data);
      this.init();
    });
  },
  methods: {
    init() {
      this.$store.state.userData.AstrolabeFriendLists.forEach(
        (AstrolabeFriend) => {
          this.List_all.push(AstrolabeFriend);
        }
      );
      this.List_filter = [...this.List_all];
    },
    filterHandler() {
      this.List_filter = this.List_all.filter(
        (item) =>
          item.Name.toUpperCase().indexOf(this.filterKey.toUpperCase()) > -1
      );
    },
    selectHandler(Astrolabe) {
      let nowSelectServiceId = this.$store.state.orderCreater.ProductID;
      let productCode = this.$store.state.productList.find(
        (item) => item.ProductID === nowSelectServiceId
      ).Code;
      if (productCode == "HouseInterpretation") {
        if (Astrolabe.IsUnlockBC) return alert("該好友已經解鎖過此服務囉");
      }
      if (productCode == "TransitChart") {
        if (Astrolabe.IsUnlockLC) return alert("該好友已經解鎖過此服務囉");
      }
      this.selectObj = Astrolabe;
    },
    next() {
      if (!this.selectObj.AstrolabeFileId) return;
      let orderObj = this.$store.state.orderCreater;
      orderObj.AstrolabeFileIds = [this.selectObj.AstrolabeFileId * 1];
      this.$store.commit("updateOrderCreater", orderObj);
      this.$router.push("/store/tobuy/");
    },
    toAddFriend() {
      this.$router.push("/friend-create");
    },
    editHandler(Astrolabe) {
      window.localStorage.setItem("toWhere", "/store/selectTarget");
      if (Astrolabe.Relationship === "R0" && Astrolabe.IsBelongToMember) {
        //自己
        window.localStorage.setItem("editMe", Astrolabe.AstrolabeFileId);
        this.$router.push({ path: "/member-edit" });
      } else {
        //好友
        window.localStorage.setItem("editUser", Astrolabe.AstrolabeFileId);
        this.$store.state.userBeEdit = Astrolabe;
        this.$router.push({ path: "/friend-edit" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";

@import "@/scss/dialog.scss";
.wrap#selectTarget {
  padding-top: 20px;
  width: 100%;
  max-width: 989px;
  margin: 0 auto;
  min-height: 100vh;
  letter-spacing: 0.08em;
  @include pad {
    padding: 20px 23px 0;
  }
  .header {
    display: flex;
    @include pad {
      flex-direction: column;
      align-items: center;
    }
    h2.st_title {
      font-weight: 500;
      font-size: 40px;
      line-height: 58px;
      color: $theme-color-2;
      display: flex;
      align-items: center;
      margin-right: auto;
      @include mobile {
        margin: 0 auto;
        font-size: 18px;
        line-height: 26px;
        justify-content: center;
      }
      img {
        width: 54px;
        margin-right: 16px;
        @include mobile {
          width: 30px;
          margin-right: 5px;
        }
      }
    }

    .filterBar {
      margin: 42px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mobile {
        margin-top: 24px;
      }
      input,
      input:focus {
        width: 400px;
        height: 36px;
        background: #f0f2f4;
        box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 25px;
        outline: none;
        padding: 8px 46px;
        @include mobile {
          max-width: 179px;
          height: 36px;
        }
      }
      input::placeholder {
        font-size: 14px;
        line-height: 20px;
        color: #d9d9d9;
      }
      label {
        position: relative;
        .serachIcon {
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .filter_btn {
        @include btn-default(112px, 36px);
        border-radius: 100px;
        margin-left: 17px;
        @include mobile {
          @include btn-default(72px, 36px);
        }
      }
      .addFriends_btn {
        @include btn(40px, 40px, $theme-color-2, #fff);
        border-radius: 50%;
        margin-left: 30px;
        @include mobile {
          margin-left: 20px;
        }
        img {
          width: 90%;
        }
      }
    }
  }
  .st_mode_tab {
    width: 100%;
    display: flex;
    margin-bottom: 40px;
    & > div {
      width: 50%;
      height: 60px;
      border: 2px solid #798da5;
      color: #9b9b9b;
      cursor: pointer;
      @include center;
      @include mobile {
        height: 36px;
      }
    }
    & > div.active {
      background: #798da5;
      color: #fff;
    }
  }
  .st_list_group {
    width: 100%;
  }
  .next_contaner {
    .nextBtn {
      @include btn-default(375px, 64px);
      @include mobile {
        @include btn-default(250px, 56px);
        margin-bottom: 32px;
      }
    }
    @include mobile {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 120px;
      @include center;
      backdrop-filter: blur(10px);
    }
  }
}
.TargetCard-space {
  margin-bottom: 20px;
  @include mobile {
    margin-bottom: 16px;
  }
}
</style>
